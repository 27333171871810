export class Competence {
  name: string;
  description: string;
  level: number;
  iconPath: string;

  constructor(
    name: string,
    description: string,
    level: number,
    iconPath: string
  ) {
    this.name = name;
    this.description = description;
    this.level = level;
    this.iconPath = iconPath;
  }
}
