
import { Options, Vue } from "vue-class-component";
import { Competence } from "./../classes/competence.class";

@Options({
  name: "Compétences",
  data() {
    return {
      skillD: this.skillDisplayed,
      skillToDisplay: this.skillToDisplay,
    };
  },
})
export default class Skills extends Vue {
  allMySkills?: Competence[];
  skillToDisplay?: Competence;
  public skillDisplayed?: boolean;
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Compétences");
    /* eslint-disable @typescript-eslint/no-var-requires */
    this.allMySkills = [
      new Competence(
        "Angular",
        "Angular2+, surtout les versions les plus récentes (11, 12, 13). Ma plus grande exprérience avec un framework",
        3.75,
        //
        require("../assets/competences/Angular.svg")
      ),
      new Competence(
        "Apps Script",
        "Google Apps Script, utilisé pour créer une surcouche de google sheets pour des besoins persos",
        1.5,
        require("../assets/competences/Apps_Script.svg")
      ),
      new Competence(
        "BDDs",
        "SQL, NoSQL, Firestore, ... et également quelques ORM tel que Sequelize et Hibernate",
        2,
        require("../assets/competences/database.svg")
      ),
      new Competence(
        "Firebase",
        "Firebase, Firestore, Cloud Functions, Pour projets perso comme pour les projets pro je trouve Firebase vraiment pratique et facile d'accès !",
        2.5,
        require("../assets/competences/Firebase.svg")
      ),
      new Competence(
        "Git",
        "git m'a suivi dans tous mes projets, et également quelques outils comme GitKraken",
        4,
        require("../assets/competences/Git.svg")
      ),
      new Competence(
        "Gitlab",
        "Gitlab, Git, Gitlab CI, Gitlab CI/CD, Gitlab Runner, Suivi d'issues et milestones ...",
        2.5,
        require("../assets/competences/Gitlab.svg")
      ),
      new Competence(
        "Ionic",
        "Developpement mobile cross platform avec Ionic spécialisé iOS",
        2,
        require("../assets/competences/Ionic.svg")
      ),
      new Competence(
        "Java",
        "Java, Spring, Spring MVC avec un profil plutôt junior à mes débuts",
        1.5,
        require("../assets/competences/Java.svg")
      ),
      new Competence(
        "Javascript",
        "Javascript, Typescript, ES6, et suffisamment à l'aise pour travailler sur ses frameworks meme nouveau...",
        4,
        require("../assets/competences/Javascript.svg")
      ),
      new Competence(
        "Jira",
        "Jira, Jira Agile, Jira Service Desk",
        2,
        require("../assets/competences/Jira.svg")
      ),
      new Competence(
        "NodeJS",
        "Régulièrement utilisé pour à peu près tous mes projets, mais jamais nativement pour coder",
        2.75,
        require("../assets/competences/Node.svg")
      ),
      new Competence(
        "NestJS",
        "Principale activité de mes réalisations backend furent sous NestJs, avec comme ORM sequelize",
        2.5,
        require("../assets/competences/Nestjs.svg")
      ),
      new Competence(
        "ThreeJS",
        "Utilisé pour réaliser des modèles 3D, voir https://threejs.org",
        1.75,
        require("../assets/competences/Three.svg")
      ),
      new Competence(
        "Vue",
        "Vue, Vuex en version 3, pour un projet perso seulement, ce site est fait avec Vue ! 😁",
        1.5,
        require("../assets/competences/Vue.svg")
      ),
    ];
  }
  mounted(): void {
    if (window.innerWidth > 640) {
      document.getElementById("line")?.addEventListener("animationend", () => {
        this.allMySkills?.forEach((skill) => {
          document.getElementById(skill.name)?.classList.remove("hide");
        });
      });
    } else {
      this.allMySkills?.forEach((skill) => {
        document.getElementById(skill.name)?.classList.remove("hide");
      });
    }
    // listen to left arrow and right arrow to navigate to /curiculum-vitae and /loisirs
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        this.goToCuriculumVitaePage();
      } else if (event.key === "ArrowRight") {
        this.goToContactPage();
      }
    });
  }
  placeImageOnCenterOfScreenWithOverlay(idOfImg: string): void {
    if (this.skillDisplayed) {
      this.removeOverlay();
    } else {
      this.skillToDisplay = this.allMySkills?.find(
        (skill) => skill.name === idOfImg
      );
      document
        .getElementById("wheatline")
        ?.classList.add("clip-from-left-to-right");
      document
        .getElementById(idOfImg)
        ?.classList.add("place-on-center-of-screen-with-overlay");
      document
        .getElementById(idOfImg + "-img")
        ?.classList.add("skill-logo-overlayed");
      document.getElementById(idOfImg + "-img")?.classList.remove("skill-logo");
      this.skillDisplayed = true;
      // remove hide class
      document.getElementById("close-img")?.classList.remove("hide");
      document.getElementById("skill-details")?.classList.remove("hide");
      document
        .getElementById("wheatline")
        .addEventListener("animationend", () => {
          document
            .getElementById("wheatline")
            ?.classList.remove("clip-from-left-to-right");
          document.getElementById("wheatline")?.classList.add("shadowed");
        });
    }
  }
  removeOverlay(): void {
    this.skillDisplayed = false;
    this.skillToDisplay = undefined;
    console.log(this.skillToDisplay);
    this.allMySkills?.forEach((skill) => {
      document
        .getElementById(skill.name)
        ?.classList.remove("place-on-center-of-screen-with-overlay");
      document
        .getElementById(skill.name + "-img")
        ?.classList.remove("skill-logo-overlayed");
      document.getElementById(skill.name + "-img")?.classList.add("skill-logo");
    });
    // add hide class
    document.getElementById("close-img")?.classList.add("hide");
    document.getElementById("skill-details")?.classList.add("hide");
  }
  goToCuriculumVitaePage(): void {
    document.getElementById("skills")?.classList.add("fade-out-right");
    document.getElementById("skills")?.addEventListener("animationend", () => {
      this.$router.push("/curiculum-vitae");
      this.$store.commit("setCurrentPageTitle", "Curiculum Vitae");
    });
  }
  goToContactPage(): void {
    document.getElementById("skills")?.classList.add("fade-out-left");
    document.getElementById("skills")?.addEventListener("animationend", () => {
      this.$router.push("/contact");
      this.$store.commit("setCurrentPageTitle", "Contact");
    });
  }
}
